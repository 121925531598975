import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import srcClose from '../../assets/academy/close.svg';
import styles from './terms.module.scss';

export const CampaignLandingAcademyTermsPageQuery = graphql`
  query CampaignLandingAcademyTermsPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CampaignLandingAcademyTermsPage = ({
  location,
}: PageProps<null, null, { back: boolean }>) => {
  const { t, originalPath, i18n, navigate } = useI18next();

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.main}>
        <span
          onClick={() => {
            if (location.state?.back) {
              window.history.back();
            } else {
              navigate('/academy/');
            }
          }}
          className={styles.close}
        >
          <img
            src={srcClose}
            width={24}
            height={24}
            alt="Close"
            loading="lazy"
          />
        </span>
        <h1>キャンペーン利用規約</h1>
        <ol>
          <li>
            <b>優勝者の決定基準:</b>
            <span>
              コンペ対象期間中の連続する6カ月間に、ECサイトのCVR（コンバージョン率/以下CVRと記述）を最も上昇させた参加者を優勝とします。CVRは取引と訪問の比率で計算します。
              (コンバージョン率 = トランザクション数 ÷ ウェブサイト訪問数）
            </span>
          </li>
          <li>
            <b>参加資格:</b>
            <span>
              業種を問わず、ECサイト開設から6カ月以上経過している、オンライン上での売上が月間50万円以上の既存事業者。Smartpayの導入事業者に限らず、すべてのEC事業者が参加できます。ただし、参加資格の確認に必要な資料の提出を弊社が求めた場合、参加者はそれに応じるものとし、資料の提出を拒む場合は参加資格がないものとみなします。
            </span>
          </li>
          <li>
            <b>コンペ対象期間:</b>
            <span>
              2022年4月1日〜2023年下期の期間終了日。このうちの6カ月間に連続して参加する必要があります。新規エントリーは2023年前半に締め切ります。正確な期間終了日および締め切りの日程は、後日Smartpayより参加者の皆様へ通知いたします。ただし、弊社の事情により、予定よりも早期にエントリーを締め切る場合もありますのであらかじめご了承ください。
            </span>
          </li>
          <li>
            <b>CVR推移の計測:</b>
            <span>
              すべてのコンペ参加者は、Smartpayコンペポータルにて毎月、測定基準値（コンバージョン指標のみ）を自己申告する必要があります。
            </span>
          </li>
          <li>
            <b>CVR証明資料の提示:</b>
            <span>
              優勝候補となった参加者は、自己申告した値の裏付け資料として、当該期間の毎月の基礎データ等を開示する必要があります。弊社が指定する期間内に当該資料が提出されない場合は失格とし、繰り上げで優勝候補を決定します。万が一、弊社に開示した資料に虚偽があることが判明した場合には、事後であっても報奨金は全額返金となります。
            </span>
          </li>
          <li>
            <b>CVR同率の場合の優勝者決定基準:</b>
            <span>
              1.トランザクション数 2.売上高が最も多い参加者を優勝とします。
            </span>
          </li>
          <li>
            <b>優勝賞品:</b>
            <span>
              優勝者には、報奨金1000万円とSmartpayのアンバサダー（ひろゆき氏）によるコーチングセッション（詳細は確定後に公開）が贈られます。
            </span>
          </li>
          <li>
            <b>当社マーケティングへの協力:</b>
            <span>
              優勝者には、賞品授与やケーススタディの準備に関連するPR活動等、その経験を他の参加者やSmartpayの顧客と共有するPR活動へのご協力をお願いします。
            </span>
          </li>
          <li>
            <span>
              本コンペの内容は、事前の予告なく変更となる可能性があります。また、本コンペへの参加をもって、本規約に同意したものとみなします。
            </span>
          </li>
          <li>
            <span>
              本規約に関する準拠法は日本法とし、専属的管轄裁判所は弊社の本社の所在地を管轄する裁判所とします。
            </span>
          </li>
          <li>
            <span>
              本規約に同意して参加する上で、いかなる損害が発生した場合も、弊社は一切責任を負いません。
            </span>
          </li>
          <li>
            <span>
              本規約に定めのない事項については、弊社の判断により判断・実行するものとします。
            </span>
          </li>
        </ol>
        <p>
          Smartpayが提供するECグロースアカデミーでは、無料で好きな時間にECビジネスの基本をオンラインで学習できます。受講者には、業績アップコンペの参加リンクを含むご案内メールをお送りしますので、登録漏れがなく便利です。ぜひ、受講をご検討ください。
        </p>
        <p>
          <br />
          最終更新日：2023年1月30日
        </p>
      </div>
    </div>
  );
};

export default CampaignLandingAcademyTermsPage;
